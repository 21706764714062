<template>
<div class="reviews-container">
    <div style="display: flex; justify-content: space-between; width: 100%; gap: 15px;">
        <vs-button size="large" block @click="$router.push({path: '/explore/poi/' + $route.params.id + '/reviews/new/' + (userReview != null ? userReview.id : '')})"><img src="../assets/icons/add.png" style="margin-right: 5px; width: 20px;" />
            <p v-if="userReview == null">{{ $t('reviews.newReview') }}</p>
            <p v-else>{{ $t('reviews.editReview') }}</p>
        </vs-button>
    </div>
    <Review v-for="review in reviews" :key="'review-' + review.id" :review="review" :user="user" />
    <div v-if="reviews == null || reviews.length == 0" style="display: flex; flex-direction: column; gap: 20px; align-items: center;">
        <img src="../assets/images/no_reviews.png" style="width: 50%;" />
        <h3>{{ $t('reviews.noReviews') }}</h3>
        <p>{{ $t('reviews.shareYourOpinion') }}</p>
    </div>
</div>
</template>

<script>
import {
    apiCall
} from '../utils/ApiMiddleware.js';
import Review from './Review.vue';
export default {
    name: "reviews",
    props: {
        poi: Object,
        user: Object
    },
    components: {
        Review,
    },

    data() {
        return {
            reviews: [],
            userReview: null,

            result: null,
            after: null
        }
    },

    mounted() {
        console.log('REVIEWS USER')
        console.log(this.user)
        if (this.poi != null) {
            this.getReviews();
        }
        let el = document.getElementsByClassName('slideout')[0] || document.getElementsByClassName('explore-container')[0];
        el.onscroll = () => {
            let e = document.getElementsByClassName('slideout')[0] || document.getElementsByClassName('explore-container')[0];
            let bottomOfWindow = Math.abs(e.scrollTop - (e.scrollHeight - e.offsetHeight)) < 20;
            if (bottomOfWindow) {
                if (this.result != null && this.result.after != null) { // non ho finito le pagine e non sta caricando
                    this.after = this.result.after;
                }
            }
        };
    },

    watch: {
        poi(n) {
            if (n != null) {
                this.getReviews();
            }
        },
        after() {
            this.getReviews(false);
        }
    },

    methods: {
        async getReviews(reset = true) {
            const loading = this.$vs.loading();
            const response = await apiCall('GET', '/reviews', {
                poi_id: this.poi.id,
                after: this.after
            });
            if (response.status == 200) {
                if (reset) {
                    this.reviews = [];
                }
                this.result = response.data;
                if (response.data.data != null) {                    
                    this.reviews = this.reviews.concat(response.data.data);
                    this.userReview = this.reviews.find(x => x.user.id == this.user.id);
                    this.reviews = response.data.data.filter(e => e.is_published);
                }

            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.messages.cannotLoadReviews'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
            loading.close();
        },
    }
}
</script>

<style scoped>
.reviews-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

p,
h3 {
    margin: 0;
}
</style>
